/* eslint-disable max-depth */
import { apiEndpoints } from "@/constants/api";
import { routeNames } from "@/constants/routes/routeNames";
import { getFetcher } from "@/services/onboarding";
import Typography from "@/uiCore/typography";
import { notification } from "antd";
import theme from "@/styles/theme";
import { clearTokens } from "./cookieStorage";
import { VENDOR_SETUP_STATUS } from "@/constants/signup";
import { mapRoutes } from "@/constants/landingPage";

const { stackSetupStatus } = apiEndpoints;
const { setupWorkspace, provisioning, home } = routeNames;

export const fetchRouting = async (
  router: any,
  // callbackFunc = () => {
  //   // callback function
  // },
  callbacks = {
    onComplete: () => {
      // default onComplete callback
    },
    onError: () => {
      // default onError callback
    },
  },
) => {
  try {
    const res = await getFetcher(stackSetupStatus);
    if (res?.status === "SUCCESS") {
      const vendorSetupStatus =
        res.data.workspaceSetup.vendorSetupStatus.toLowerCase();
      if (VENDOR_SETUP_STATUS.includes(vendorSetupStatus)) {
        if (vendorSetupStatus === "complete") {
          const route = sessionStorage.getItem("defaultLandingPage");
          if (route) {
            router.push(mapRoutes[route]);
          } else {
            callbacks.onComplete();
          }
        } else if (vendorSetupStatus === "not_created") {
          router.push(setupWorkspace);
        } else if (["failed", "incomplete"].includes(vendorSetupStatus)) {
          router.push(provisioning);
          notification.open({
            key: "error",
            duration: 20,
            message: (
              <Typography.P1 color={theme.textPrimary}>
                Stack provisioning failed. Contact support
              </Typography.P1>
            ),
          });
        } else if (vendorSetupStatus === "in_progress") {
          router.push(provisioning);
        }
      }
    } else {
      clearTokens();
      callbacks.onError();
    }
  } catch (error) {
    notification.open({
      key: "error",
      duration: 20,
      message: (
        <Typography.P1 color={theme.textPrimary}>
          An error occurred. Contact support
        </Typography.P1>
      ),
    });
    router.push(home);
  }
};
