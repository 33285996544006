"use client";

import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { getNookies } from "@/helpers/getCookies";
import { fetchRouting } from "@/helpers/protectRoute";
import { Flex } from "@/styles/styled/styled";
import { APP_TITLE } from "../constants/window";
import { preloadIcons } from "@/utils/helper";

const Signup = dynamic(() => import("@/components/signup"));
const FivexLoader = dynamic(() => import("@/components/loader/FivexLoader"));

type HomePageProps = {
  token: string;
};

export async function getServerSideProps(context: object) {
  const cookies: { accessToken?: string } = (await getNookies(context)) as {
    accessToken?: string;
  };
  const { accessToken } = cookies || {};

  return {
    props: { token: accessToken || null }, // Pass props to your page here
  };
}

const HomePage: React.FC<HomePageProps> = ({ token }) => {
  const [isSignup, setIsSignup] = React.useState<boolean>(false);

  const router = useRouter();

  useEffect(() => {
    // Preload the images of the platform for faster loading
    preloadIcons();
  }, []);

  useEffect(() => {
    if (token) {
      fetchRouting(router, {
        onComplete: () => {
          // callback function
        },
        onError: () => setIsSignup(true),
      });
    } else {
      setIsSignup(true);
    }
  }, [token]);

  return (
    <>
      <title>{APP_TITLE} - Signup</title>
      {isSignup ? (
        <Signup />
      ) : (
        <Flex height={"100vh"}>
          <FivexLoader />
        </Flex>
      )}
    </>
  );
};

export default HomePage;
